header {
    background-size: cover;
    background-position: center;
}



.home-page header h1,
.home-page header h2 {
    text-shadow: 2px 2px 8px #ffffff;
}


img {
    border-style: solid;
    border-width: 0px;
}

